import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedCard from "../../../components/featured-card/featured-card"
import {
  FeaturedPostWrapper,
  FeaturedPostRow,
  FeaturedPostCol,
  SecTitle,
} from "./style"

type FeaturedPostsProps = {}

const FeaturedPosts: React.FunctionComponent<FeaturedPostsProps> = (props) => {
  const Data = useStaticQuery(graphql`
    query {
      ghostSettings {
        title
      }
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          context {
            numPages
            currentPage
          }
        }
      }

      allGhostPost(filter: {featured: {eq: true}, slug: {ne: "data-schema"}}, sort: {fields: published_at, order: DESC}, limit: 3) {
        edges {
          node {
            excerpt
            slug
            published_at(formatString: "MMM DD, YYYY")
            title
            meta_description
            tags {
              slug
            }
            feature_image_sharp {
              childImageSharp {
                fluid(maxWidth: 570, maxHeight: 810, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  const Posts = Data.allGhostPost.edges

  return (
    <FeaturedPostWrapper>
      <SecTitle>Featured Stories</SecTitle>
      <FeaturedPostRow>
        {Posts.map(({ node }: any) => {
          const title = node.title || node.slug
          return (
            <FeaturedPostCol key={title}>
              <FeaturedCard
                title={title}
                image={
                  node.feature_image_sharp === null
                    ? null
                    : node.feature_image_sharp.childImageSharp.fluid
                }
                url={node.slug}
                tags={node.tags.map(({ slug }: any) => slug)}
                description={node.excerpt || node.meta_description}
              />
            </FeaturedPostCol>
          )
        })}
      </FeaturedPostRow>
    </FeaturedPostWrapper>
  )
}

export default FeaturedPosts
