import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import SEO from "../../../components/seo";
import SocialProfile from '../../../components/social-profile/social-profile';
import {
  IntroWrapper,
  IntroTitle,
  Desciption,
  BgText,
  IntroContentWrapper,
} from './style';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoGithub,
} from 'react-icons/io';

type IntroProps = {};

const SocialLinks = [
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/yutinef/',
    tooltip: 'Instagram',
  },
];
const StyledImg = styled(Img)`
  opacity: 0.5;
  width: 100%;
  @media (max-width: 1500px) {
    width: 100%;
  }
  @media (max-width: 1199px) {
    width: 110%;
  }
  @media (max-width: 990px) {
    width: 120%;
  }
  @media (max-width: 767px) {
    width: 120%;
  }
  @media (max-width: 575px) {
    width: 150%;
  }
`;

const Intro: React.FunctionComponent<IntroProps> = (props) => {
  const Data = useStaticQuery(graphql`
    query {
      ghostAuthor {
        name
      }
      site {
        siteMetadata {
          title
          description
        }
      }
      ghostPage(slug: {eq: "home"}) {
        title
        html
        excerpt
        meta_title
        meta_description
        feature_image_sharp {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1170, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 1818, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  `);

  const { ghostPage, site } = Data;

  return (
    <IntroWrapper>
      <SEO
          title={ghostPage.meta_title || "Home"}
          description={site.siteMetadata.description}
        />
      {ghostPage.feature_image_sharp ? (
            <Parallax 
            className="custom-class" 
            y={[-99, 0]} 
            tagOuter="figure" 
            styleOuter={{
              overflow: 'hidden',
              position: 'relative', 
              width: '100%', 
              height: '100%', 
              marginLeft: '0', 
              marginRight: '0'
            }} 
            styleInner={{ position: 'relative' }}>
              <StyledImg fluid={ghostPage.feature_image_sharp.childImageSharp.fluid} />
              {/* <StyledImg fixed={ghostPage.feature_image_sharp.childImageSharp.fixed} /> */}
            </Parallax>
          ) : ''}
      <IntroContentWrapper>
        <IntroTitle>{ghostPage.title}</IntroTitle>
        <Desciption className="load-external-scripts" dangerouslySetInnerHTML={{ __html: ghostPage.html }}/>
        <SocialProfile items={SocialLinks} />
      </IntroContentWrapper>
    </IntroWrapper>
  );
};

export default Intro;
