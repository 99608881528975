import React from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from 'react-scroll-parallax';
import Layout from "../components/layout"
import PersonalBlog from "../containers/home"
import Helmet from "react-helmet";

const PersonalBlogPage = (props: any) => {
  const { data } = props

  return (
    <ParallaxProvider>
      <Helmet>
        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Helmet>
      <Layout>
        <PersonalBlog />
      </Layout>
    </ParallaxProvider>
  )
}

export default PersonalBlogPage

export const pageQuery = graphql`
  query {
    ghostAuthor {
      name
    }
    ghostSettings {
      title
      description
    }
    ghostPage(slug: {eq: ""}) {
      title
      html
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
