import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Masonry from "react-masonry-component"
import MasonryCard from "../../../components/masonry-card/masonry-card"
import Pagination from "../../../components/pagination/pagination"
import BlogPostsWrapper, { PostRow, PostCol, SecTitle } from "./style"

type PostsProps = {}

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      ghostSettings {
        title
      }
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          context {
            numPages
            currentPage
          }
        }
      }
      allGhostPost(filter: {featured: {eq: false}, slug: {ne: "data-schema" }}, sort: {fields: published_at, order: DESC}, limit: 6) {
        edges {
          node {
            excerpt
            slug
            published_at(formatString: "MMM DD, YYYY")
            title
            meta_description
            tags {
              slug
            }
            feature_image_sharp {
              childImageSharp {
                fluid(maxWidth: 570, maxHeight: 810, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  const Posts = Data.allGhostPost.edges
  const TotalPage = Data.allSitePage.nodes[0].context.numPages
  const CurrentPage = Data.allSitePage.nodes[0].context.currentPage

  return (
    <BlogPostsWrapper>
      <SecTitle>Latest Stories</SecTitle>
      <PostRow>
        <Masonry className="showcase">
          {Posts.map(({ node }: any) => {
            const title = node.title || node.slug
            return (
              <PostCol key={node.slug}>
                <MasonryCard
                  title={title}
                  image={
                    node.feature_image_sharp == null
                      ? null
                      : node.feature_image_sharp.childImageSharp.fluid
                  }
                  url={node.slug}
                  date={node.published_at}
                  tags={node.tags.map(({ slug }: any) => slug)}
                  readTime={''}
                />
              </PostCol>
            )
          })}
        </Masonry>
      </PostRow>
      {TotalPage >> 1 ? (
        <Pagination
          nextLink="/page/2"
          currentPage={CurrentPage}
          totalPage={TotalPage}
          className="pagination"
        />
      ) : (
        ""
      )}
    </BlogPostsWrapper>
  )
}

export default Posts
